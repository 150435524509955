import { JSONTree } from 'react-json-tree';
import React, { useState, useEffect, useCallback } from 'react';
import styles from './JsonViewer.module.css';

export default function JsonViewer({ data }) {
  const [parsedData, setParsedData] = useState(null);
  const [error, setError] = useState(null);
  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    alignItems: 'left',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const [matches, setMatches] = useState(0);
  const [isTreeExpanded, setIsTreeExpanded] = useState(true);

  const handleExpandClick = () => {
    setIsTreeExpanded(true);
  };

  const handleCollapseClick = () => {
    setIsTreeExpanded(false);
  };

  const shouldExpandNode = () => isTreeExpanded;

  const searchTree = useCallback((node, term) => {
    let localMatchCount = 0;

    const checkMatch = (item) => {
      const itemString = String(item).toLowerCase();
      if (itemString.includes(term.toLowerCase())) {
        localMatchCount++;
      }
    };
    

    const traverse = (node) => {
      if (Array.isArray(node)) {
        node.forEach(item => traverse(item, term));
      } else if (node && typeof node === 'object') {
        Object.values(node).forEach(value => traverse(value, term));
      } else {
        checkMatch(node);
      }
    };

    traverse(node, term);
    return localMatchCount;
  }, []);
  
  const handleSearchClick = () => {
    // Reset match count
    setMatches(0);
    if (error) {
      return;
    }

    if (!searchTerm) {
      setActiveSearchTerm(searchTerm);
      return; // Early exit if search term is empty
    }

    setActiveSearchTerm(searchTerm);
    const result = typeof data === 'string' ? JSON.parse(data) : data;
    const matchCount = searchTree(result, searchTerm);
    setMatches(matchCount);
  };

  const isImageUrl = value => {
    const imagePattern = /\.(jpeg|jpg|gif|png|svg|webp)/i;
    const dataUriPattern = /data:image\/(jpeg|jpg|gif|png|svg|webp);base64,/i;
    const httpPattern = /http/i;

    return imagePattern.test(value) || dataUriPattern.test(value) || httpPattern.test(value);
  };  

  // Function to copy value to clipboard
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      // Optionally, display an alert or a tooltip to the user indicating the value was copied
    }).catch(err => {
      console.error('Failed to copy value to clipboard', err);
    });
  };

  useEffect(() => {
    try {
      const result = typeof data === 'string' ? JSON.parse(data) : data;
      setParsedData(result);
      setError(null); // Reset error state if parsing is successful
    } catch (parseError) {
      setError(`Invalid JSON: ${parseError.message}`);
      setParsedData(null); // Clear any previously valid data
    }
  }, [data]); // Re-run the effect if the 'data' prop changes

  return (
    <div className={styles.container}>
      {/* Search bar with a modern look */}
      <div className={`flex items-center justify-between p-2 bg-gray-500 ${styles.stickyHeader}`}>
        {/* Search bar and related elements */}
        <label className="text-white">Search value:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow mx-2 p-2 border-2 border-gray-300 rounded"
          placeholder="Enter search term..."
        />
        <button
          onClick={handleSearchClick}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Search
        </button>
        <span className="ml-4 text-white">Matches: {matches}</span>
      </div>
      <div className={`overflow-auto ${styles.jsonTreeScrollable}`}>
      <div className="bg-monokai p-4">
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
        <JSONTree data={parsedData} 
                  theme={theme}
                  shouldExpandNodeInitially={()=>true}
                  valueRenderer={(raw, value) => {
                    const stringValue = String(value);
                    const regex = new RegExp(`(${activeSearchTerm})`, 'gi');
                    const isMatch = regex.test(stringValue);
                    const parts = stringValue.split(regex);
                  
                    // For image URLs
                    if (typeof value === 'string' && isImageUrl(value)) {
                      return (
                        <div className={styles.tooltip}>
                          <span className='cursor-copy' onClick={() => copyToClipboard(stringValue)}>
                            {isMatch ? (
                              parts.map((part, index) => {
                                const isHighlighted = part.toLowerCase() === activeSearchTerm.toLowerCase();
                                return isHighlighted ? (
                                  <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
                                ) : (
                                  <span key={index}>{part}</span>
                                );
                              })
                            ) : (
                              raw // if there's no match, just return the raw value
                            )}
                          </span>
                          <span className={styles.tooltipText}>
                            <img src={stringValue} alt="preview" style={{ maxWidth: '300px'}} />
                          </span>
                        </div>
                      );
                    }
                  
                    // For other values
                    return (
                      <span className='cursor-copy' onClick={() => copyToClipboard(stringValue)}>
                        {isMatch ? (
                          parts.map((part, index) => {
                            const isHighlighted = part.toLowerCase() === activeSearchTerm.toLowerCase();
                            return isHighlighted ? (
                              <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
                            ) : (
                              <span key={index}>{part}</span>
                            );
                          })
                        ) : (
                          raw // if there's no match, just return the raw value
                        )}
                      </span>
                    );
                  }}
                  
                           
        />
      )}
      </div>
    </div>
    </div>
  );
}
