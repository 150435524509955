import React, { useState } from 'react';

export default function JsonInput({ onJsonChange, onShowIntro, initialJson }) {
  const [input, setInput] = useState(JSON.stringify(initialJson, null, 2));

  const handleChange = (event) => {
    setInput(event.target.value);
    onJsonChange(event.target.value);
  };

  const handleClear = () => {
    setInput('');
    onJsonChange('');
  };

  return (
    <div className="flex flex-col h-full bg-gray-600"> {/* Background color adjusted */}
      <div className="flex items-center justify-between p-2 bg-gray-600"> {/* Container for buttons */}
        {/* Show Intro Button */}
        <button
          onClick={onShowIntro}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  justify-start rounded focus:outline-none focus:shadow-outline"
        >
          Show Intro
        </button>
        {/* Clear Button */}
        <button
          onClick={handleClear}
          className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 justify-end rounded focus:outline-none focus:shadow-outline"
        >
          Clear
        </button>
      </div>
      <textarea
        value={input}
        onChange={handleChange}
        className="flex-grow p-2 border-2 border-gray-300 rounded resize-none bg-white"
        placeholder="Paste your JSON here..."
      />
    </div>
  );
}
