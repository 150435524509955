import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import JsonInput from './components/JsonInput';
import JsonViewer from './components/JsonViewer';
import introData from './assets/intro.json';

function App() {
  const [jsonData, setJsonData] = useState(introData);  // Remove the <any> type
  const showIntro = () => {
    setJsonData(introData);
  };


  return (
    <div className="App">
      <nav className="bg-gray-800 text-white px-4 py-2 flex items-center">
        <span className="text-xl font-semibold">Coder's Utilities</span>
      </nav>
      <div className="flex h-[80vh]">
        <div className="w-1/2 overflow-auto">
          <JsonInput onJsonChange={setJsonData}
                     onShowIntro={showIntro} // Add this prop to JsonInput
                     initialJson={introData}
           />
        </div>
        <div className="w-1/2 overflow-auto">
          <JsonViewer data={jsonData} />
        </div>
      </div>
    </div>
  );
}

export default App;
